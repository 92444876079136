import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import {
    ArrowRoundTopLeftIcon,
    Banner,
    ButtonGroup,
    Card,
    DaButton,
    Grid,
    InternalGrid,
    Title,
    Text,
} from '@paygreen/paygreen-ui';
import { Layout, SEO } from '../components';

const ContainerPage = styled.div`
    max-width: 1000px;
    margin: 0 auto;
`;

const NotFoundPage = () => {
    const { t } = useTranslation('pages');

    return (
        <Layout>
            <SEO title="404: Not found" />

            <Banner gradient="brand" topStyle="none" isFirstContent={true}>
                <Grid alignItems="center">
                    <Card hasBackground={false} blockWidth="lg">
                        <Title
                            htmlTag="h1"
                            colorType="reverse"
                            textSize="xl"
                            marginTop="lg"
                            hasUnderline={true}
                        >
                            {t('404.title')}
                        </Title>
                    </Card>
                </Grid>
            </Banner>

            <ContainerPage>
                <InternalGrid
                    displayType="grid"
                    alignItems="center"
                    justifyItems="center"
                >
                    <Text textSize="sm" blockWidth="sm">
                        {t('404.text')}
                    </Text>

                    <ButtonGroup marginTop="sm">
                        <Link to="/">
                            <DaButton
                                gradient="brand"
                                icon={<ArrowRoundTopLeftIcon />}
                            >
                                {t('404.backHome')}
                            </DaButton>
                        </Link>
                    </ButtonGroup>
                </InternalGrid>
            </ContainerPage>
        </Layout>
    );
};

export default NotFoundPage;
